<template>
  <div class="home"> 
    <Header>
            <div class="header_section">
          
            <div class="small-container">  
              <img class="img" src="../assets/res/Filter.png" alt="" srcset="">
            <img class="img" src="../assets/res/AdobeStock_341167197 1.png" style="opacity: 0.2;" alt="" srcset="">
          
            <div class="overlay">
              
             <p class="title">WELCOME TO DATA SYSTEMS LIMITED</p>
             
             <p class="small_content">
              Data Systems LTD is a software outsourcing company based in Rwanda, Ghana, and Dubai, that specializes in solving complex business challenges through information technology. It is focused on Customer Value Management systems (CVM) and e-Government solutions.
                 
             </p>

             <button @click="navigateToComponent('form')">GET STARTED</button>
             <img v-on:click="scrollDown" class="arrow" src="../assets/res/Arrow 1 (1).png" style="width: 15px; height: 30px;  margin-top: 200px; margin-left: -65px; cursor: pointer;" alt="" srcset="">
             
            </div>
                <img class="shape"  src="../assets/res/Shape.png" alt="" style="width: 100%; height: 40px; margin-top: 550px;" srcset="">
            </div>
            
            </div>
      </Header>
    <Main_Contents />
  </div>
</template>

<script>
  import Header from'../components/header/Header.vue'
  import Main_Contents from'../components/home_contents/main_contents/Contents.vue'
  export default{

    data(){
      return{
        img: '../../assets/res/AdobeStock_341167197 1.png'
      }
    },

    components:{
      Header,
      Main_Contents
    },

    methods:{
      scrollDown(){
        window.scrollBy({ top: 600, left:0, behavior: 'smooth' })
      },

      navigateToComponent(id) {
                const element = document.getElementById(id);
                element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  
}
 
</script>
