<template> 
  <div style="margin-top: 7vh;">
      <header>
          <div class="header_section">
              <div  class="small-container">
              <img class="img" src="../assets/res/Filter.png" alt="" srcset="">
              <img class="img" src="../assets/res/AdobeStock_3390687.png" style="opacity: 0.2;" alt="" srcset="">
            
              <div class="overlay">
                
               <p class="title" style="margin-left: 450px;">YOUR OPPORTUNITY AWAITS!</p>
               
               <p class="small_content" style="width: 600px; position: relative; left: -40px;">
                  At Data Systems LTD, we believe in equal opportunities for everyone. Join our dynamic team and unleash your potential in the world of software development. We value talent, dedication, and innovation. Your journey starts here. Apply now and let's build the future together! 
                   
               </p>
   
               <img v-on:click="scrollDown"  class="arrow" src="../assets/res/Arrow 1 (1).png" style="width: 15px; height: 30px;  margin-top: 200px; margin-top: 205px; margin-left: -10px; cursor: pointer;" alt=""  srcset=""> 
              </div>
                  <img class="shape"  src="../assets/res/Shape.png" alt="" style="width: 100%; height: 40px; margin-top: 550px;" srcset="">
              </div>
              </div>
      </header>
  </div>
      <Contents></Contents>
  </template>
  
  <script>
      import Header from '../components/header/Header.vue'
      import Contents from'../components/careers_page/Contents.vue'
      import Navbar from '@/components/navbar/Navbar.vue';
       export default{
          components: {
              Header,
              Contents,
              Navbar 
          },
  
          methods:{
              scrollDown(){
                  window.scrollBy({ top: 600, left:  0, behavior: 'smooth' })
              }
          }
       }
  </script>
  
  <style scoped>
  
  @media screen and (max-width: 1514px) {
        /* .header_section .small-container .overlay .title{ 
          margin-left: 23% !important;
        }
  
        .header_section .small-container .overlay .small_content{
          margin-left: 32% !important;
        } */
  
    }
  
    @media screen and (max-width: 1188px) {
      .header_section .small-container .img{
        object-fit: cover;
      }
    }
  
    @media screen and (max-width: 1197px) {
       .header_section .small-container .overlay .title{ 
          margin-left: 30% !important;
        }
  
        .header_section .small-container .overlay .small_content{
          margin-left: 25% !important;
          
        } 
  
    }
  
    @media screen and (max-width: 1016px) {
       .header_section .small-container .overlay .title{ 
          margin-left: 30% !important;
        }
  
        .header_section .small-container .overlay .small_content{
          margin-left: 22.5% !important;
          
        } 
  
    }
  
    @media screen and (max-width:  968.5px) {    
      .header_section .small-container .img{
        object-fit: cover;
      }
       .header_section .small-container .overlay .title{ 
          margin-left: 30% !important;
        }
  
        .header_section .small-container .overlay .small_content{
          margin-left: 22.5% !important;
          
        } 
  
    }
  
   
  
    @media screen and (max-width: 898.5px) {
       .header_section .small-container .overlay .title{ 
          margin-left: 27% !important;
        }
  
        .header_section .small-container .overlay .small_content{
          margin-left: 18% !important;
          
        } 
  
    }
  
    
    @media screen and (max-width: 851px) {
       .header_section .small-container .overlay .title{ 
          margin-left: 2.5% !important;
        }
  
        .header_section .small-container .overlay .small_content{
          margin-left: 18% !important;
          
        } 
  
    }
  
    @media screen and (max-width:  850px) {
       .header_section .small-container .overlay .title{ 
          margin-left: 25% !important;
        }
   
  
    }
  
   
  
    @media screen and (max-width: 798.5px) {
      .header_section .small-container .overlay .title{ 
         position: relative;
         top: -20px; 
         left: -12%;
        }
       .header_section .small-container .overlay .small_content{ 
         position: relative;
         top: -220px; 
        }
  
      .header_section .small-container .overlay .arrow{
        position: relative;
        top: -330px;
      }
  
    }
  
   
  
    @media screen and (max-width: 750.5px) {
      .header_section .small-container .overlay .title{ 
         position: relative;
         left: -17%;  
         text-align: left;
        }
       .header_section .small-container .overlay .small_content{ 
         position: relative;
         left: -10% !important;
         text-align: left;
         top: -250px;
         width: 400px !important;
        }
  
      .header_section .small-container .overlay .arrow{
        position: relative;
        top: 0px !important;
  
      }
  
    }
  
    .header_sectio .small-container .img{
      height: 300px !important;
    }
    
  
   @media screen and (max-width: 532.5px) {
      .header_section .small-container .overlay .arrow{
        position: relative;
        top: -30px !important;
  
      }
   }
  
   @media screen and (max-width: 444.5px) {
      .header_section .small-container .overlay .arrow{
        position: relative;
        left: 95px;
  
      }
   }
  
  
   
    
    @media screen and (max-width: 440px) {
      .header_section .small-container .overlay .small_content{  
         width: 350px !important;
        }
        .header_section .small-container .overlay .arrow{
        position: relative;
        left: 120px !important;
        margin-top: -250px !important;
  
      }
      }
  
      
      @media screen and (max-width: 397.5px) {
  
          .header_section .small-container .overlay .arrow{
              margin-top: -300px !important;
          }
  
          .header_section .small-container .overlay .small_content{  
          width: 350px !important;
        }
  
      }
  
      @media screen and (max-width: 384.5px) {
  
       
          .header_section .small-container .overlay .small_content{  
          width: 343px !important;
          }
  
      }
  
      
      
      @media screen and (max-width: 373.5px) {
  
          .header_section .small-container .overlay .arrow{
              margin-top: -300px !important;
          }
          
      }
  
  
      @media screen and (max-width: 365px) {
        .header_section .small-container .overlay .small_content{ 
          width: 300px !important;
        }
  
        .header_section .small-container .overlay .arrow{
              margin-top: -380px !important;
          }
      }
  
      
        
      
   
      
  
     
  </style>