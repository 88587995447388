<template>

    <div class="main_contents">
        <div class="col-md-6" style="padding-left: 130px; padding-right: 70px;">
                    <div class="content-title-arrow" style=" width: 407px; height: 87px; ">
                        <h2 style="font-size: 22px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654;">WE</h2>
                        <h2 style="font-size: 22px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654; position: relative; top: -8px;">ARE TECH AND WE OFFER</h2>
                        <img src="../../../assets/res/Arrow2.png" style="stroke-width: 3px; width: 43px; margin-left: -0px; margin-top: -30px;" alt="" srcset="">
                    </div>
        </div>
            <div class="row ">
                <div class="col-md-6" style="padding-left: 150px; padding-right: 70px;">
    
                    <div class="software_Outsourcing_section my-5">
                        <h6 style="text-transform: uppercase;">1. Custom Software Development</h6>
                        <p class="my-2" style="text-align: justify;">Leverage our expertise in modern technologies to develop tailored software solutions that align with your business objectives. From concept to implementation, we deliver top-quality software applications tailored to your requirements.
                        </p>
                              
                    </div>
                    <div class="software_Outsourcing_section my-5">
                        <h6 style="text-transform: uppercase;">3. E-commerce Solutions</h6>
                        <p class="my-2" style="text-align: justify;">Enhance your online presence with our cutting-edge e-commerce solutions. We design and develop user-friendly, secure, and scalable online platforms that facilitate smooth transactions, providing a seamless shopping experience for your customers.</p>
                              
                    </div>
                </div>
    
    
    
                <div class="col-md-6" style="padding-right: 150px; padding-left: 70px;">
                    <div class="software_Outsourcing_section new-contents my-5">
                        <h6>2. SOFTWARE OUTSOURCING SERVICES</h6>
                        <p class="my-2" style="text-align: justify;">Streamline your operations and boost efficiency by outsourcing your software development needs to us. Our skilled professionals provide dedicated services, ensuring seamless integration with your existing processes.
                        </p>
                              
                    </div>
                    <div class="software_Outsourcing_section new-contents my-5">
                        <h6 style="text-transform: uppercase;">4. MANAGED SERVICE PROVIDER (MSP)</h6>
                        <p class="my-2" style="text-align: justify;">Enhance your operational efficiency with our Managed Service Provider (MSP) solutions. Our dedicated team ensures seamless IT infrastructure management, allowing you to focus on core business functions. From proactive monitoring to strategic planning, our MSP solutions integrate smoothly, providing security, scalability, and cost-effectiveness. Elevate your business with confidence, relying on our expertise for strategic IT management.</p>
                              
                    </div>
                </div>
            </div>
    
            <!-- section 2 -->
    
            <div class="row my-5 section-2" style="margin-top: 120px !important;">
                <div class="col-md-6">
                    <img class="ceo" src="../../../assets/res/AdobeStock_227421922 1 (1).png" style="width: 100%; margin-left: -0px;" alt="" srcset="">
                </div>
                <div class="col-md-6" style="padding-right: 150px; padding-left: 70px;">
                    <div class="section-2-title" style=" width: 327px; height: 87px; text-align:end; margin-left: 95px;">
                        <h2 style="font-size: 27px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654;">WE</h2>
                        <h2 style="font-size: 27px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654; position: relative; top: -10px;">ALSO OFFER</h2>
                        <img src="../../../assets/res/Arrow 2.png" style="stroke-width: 3px; width: 43px; margin-left: -0px; margin-top: -30px;" alt="" srcset="">
                    </div>
                    <div class="software_Outsourcing_section n-2 my-5">
                        <h6 style="text-transform: uppercase;">Mobile App Development</h6>
                        <p class="my-2" style="text-align: justify;">Stay connected with your audience on the go. Our experienced mobile app developers create innovative and user-friendly mobile applications compatible with various platforms, including Android and iOS.</p>
                              
                    </div>
                    <div class="software_Outsourcing_section n-2 my-5">
                        <h6 style="text-transform: uppercase;">IT Equipment Sales</h6>
                        <p class="my-2" style="text-align: justify;">Explore our online store, www.giraict.rw, to find a wide range of quality electronic devices and IT equipment. We source products from reputable suppliers, ensuring reliability and performance.
                        </p>
                              
                    </div>
                </div>
    
            </div>
     
    
           
            <Products />
           
        </div>
    </template>
    
    <script>
        import Products from '../products/Products.vue'
        export default{
            components:{
                Products
            }
        }
    </script>
    
    <style scoped>
    .main_contents{
       width: 100%;
       padding-top: 70px;
       background: #F5F5FA;
       min-height: 300px; 
       overflow-y: hidden;
    }
    
    
    .main_contents::-webkit-scrollbar{
        display: none;
    }
    
    .software_Outsourcing_section h6{
       font-size: 22px;
       color: var(--title-color);
       font-weight: 600;
    }
    
    .software_Outsourcing_section p{
       font-size: 12px;
    }
    
    .software_Outsourcing_section button{
       display: inline-flex;
        padding: 6px 17px 6px 17px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        background: var(--button-bg);
        box-shadow: var(--button-box-shadow);
        border: none;
    }
    
    .software_Outsourcing_section button:hover{
       display: inline-flex;
       padding: 6px 17px 6px 17px;
       color: #fff;
       font-size: 12px;
       font-weight: 400;
       justify-content: center;
       align-items: center;
       border-radius: 32px;
       background: var(--title-color); 
       border: none;
    }
      
    
    @media  screen and (max-width:  1440px){
        .main_contents{
            overflow-x: hidden;
        }
    }  
     
     @media  screen and (max-width:  1185px){
        .section-2-title{
            position: relative;
            left: -15% !important;
        }
    }  
     
    @media  screen and (max-width:  1093px){
        .section-2-title{
            position: relative;
            left: -25% !important;
        }
    }
    
    
    @media  screen and (max-width:  1068px){
     
        .section-2-title{
            position: relative;
            left: -50px;
        }
    
    }
    
    @media  screen and (max-width:  1046px){
        .section-2-title{
            position: relative;
            left: -35% !important;
        }
    } 
    
    @media  screen and (max-width:  1022px){
        .software_Outsourcing_section h6{
            font-size: 20px;
        }
     
    }
    
    @media  screen and (max-width:  957px){ 
     
     
        .section-2-title{
            position: relative;
            left: -45% !important;
        }
    
    }
    
    @media  screen and (max-width:  890px){
        .software_Outsourcing_section{
            margin-left: -50px; 
            font-size: 16px;
        }
    
        .content-title-arrow{
            margin-left: -50px; 
        }
    
        .section-2-title{
            position: relative;
            left: -67% !important;
        } 
    }
    
    @media  screen and (max-width: 835px){
        .section-2-title{
            position: relative;
            left: -87% !important;
        }
    } 
    @media  screen and (max-width: 800px){
        .section-2-title{
            position: relative;
            left: -107% !important;
        }
        .n-2{
            margin-left: 0px !important;
            width: 133% !important;
        }
    }
    
    
    @media  screen and (max-width:  797px){
        .software_Outsourcing_section{
            margin-left: -80px; 
            font-size: 16px;
        }
    
        .content-title-arrow{
            margin-left: -80px; 
        } 
        .section-2-title{
            margin-left: 73% !important;
        }
    
    } 
    
    @media  screen and (max-width:  768px){
        .ceo{
            width: 70% !important;
            position: relative;
            left: 50px;
    
        }   
    
        .n-2{
            margin-left: 0px !important;
            width: 115% !important;
        }
    
        .section-2-title{
            margin-left: 165% !important;
              
        }
    
        .section-2{
            margin-top: 20px !important;
        }
     
    }
     
    @media  screen and (max-width:  767.5px){ 
     
        .software_Outsourcing_section{
            margin-left: -80px; 
            font-size: 16px;
        }
    
        .new-contents{ 
            margin-left: 0px !important;
            min-width: 115% !important;
        } 
       
    } 
    
    @media  screen and (max-width:  735px){  
     .section-2-title{
        margin-right: 50px !important;
     }
    
    } 
     
    @media screen and (max-width: 716px) {
        .main_contents{
            height: 3100px !important; 
        }
    }
    
    @media screen and (max-width: 700px) {
        .section-2-title{
           margin-left: 152% !important;
              
        }
    }
    
    @media screen and (max-width: 620px) {
    
         .section-2-title{
            margin-left: 142% !important;
        }
    }
    
    @media screen and (max-width: 592.5px) {
    
        .new-contents{ 
            margin-left: 0px !important;
            min-width: 120% !important;
        }
    }
    
    @media screen and (max-width: 500.5px) {
    
        .new-contents{ 
            margin-left: 0px !important;
            min-width: 128% !important;
        }
    }
    
    @media screen and (max-width: 424.5px) {
    
        .new-contents{ 
            margin-left: 0px !important;
            min-width: 137% !important;
        }
    }
    
    @media screen and (max-width: 356.5px) {
    
        .new-contents{ 
            margin-left: 0px !important;
            min-width: 149% !important;
        }
    }
    
    
    
    @media screen and (max-width: 566px) {
    
    .section-2-title{
       margin-left: 132% !important;
    }
    .n-2{
        width: 120% !important;
    }
    }
    
    @media screen and (max-width: 500px) {
    
    .section-2-title{
       margin-left: 125% !important;
    }
    .n-2{
        width: 130% !important;
    }
    
    }
    
    @media screen and (max-width: 450px) {
    
    .section-2-title{
       margin-left: 114% !important;
    }
    }
    
    @media screen and (max-width: 420px) {
    
    .section-2-title{
       margin-left: 100% !important;
    }
    .n-2{
        width: 135% !important;
    }
    
    }
    
    @media screen and (max-width: 397px) {
    
    .section-2-title{
       margin-left: 100% !important;
    }
    .n-2{
        width: 145% !important;
    }
    
    }
    
    @media screen and (max-width: 387px) {
    
    .section-2-title{
       margin-left: 80% !important;
    }
    .n-2{
        width: 145% !important;
    }
    
    }
    
    @media screen and (max-width: 365px) {
    
    .section-2-title{
       margin-left: 70% !important;
    }
    .n-2{
        width: 145% !important;
    }
    
    }
    
    
    
    
    
    </style>