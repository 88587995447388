<template> 
    <slot></slot> 
</template>
 

<style>

    .header_section{
        height: 588.698px; 
        width: 100%;   
        background-attachment: fixed;
        background-size: cover;
        background-position: center;    
        background-repeat: no-repeat;
    }

    .header_section img{
        width: 100%; 
        height: 588.698px;
        /* height: 643.698px; */
        position: absolute;
     }

     .header_section .title{
        margin-left: 456px;
     }

    .header_section .overlay p{
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        position: absolute;
        margin-left: 30%;
        margin-top: 230px;
        color: var(--title-color);
    }

    .header_section .overlay button{ 
        padding: 6px 17px 6px 17px;
        color: #fff;
        font-size: 12px;
        font-weight: 600; 
        align-items: center;
        border-radius: 32px;
        background: var(--button-bg);
        box-shadow: var(--button-box-shadow);
        border: none;
        font-weight: 600; 
        margin-top: 70px;
    }

    .header_section .overlay button:hover{ 
        padding: 6px 17px 6px 17px;
        color: #fff;
        font-size: 12px;
        font-weight: 400; 
        align-items: center;
        border-radius: 32px;
        background: var(--title-color);
        border: none;
        font-weight: 600; 
        margin-top: 70px;
    }


    .header_section .overlay .small_content{ 
        color: white;
        font-size: 13px;  
        width: 700px;
        position: relative;
        left: -100px;
        font-weight: 500;
        top: 50px; 
    }

        .header_section .small_content p{
        font-size: 14px;
        color: #fff;
        font-weight: 350; 
    }

    .overlay{
        width: 100%;
        height: 585px;
        background: #00000033;  
        position: absolute;
        text-align: center;
    }
    @media  screen and (max-width:   1110px){
        .header_section .overlay p{
        margin-left: 256px;
     }
    }

    @media  screen and (max-width:   968px){
        .header_section .overlay p{
        margin-left: 156px;
     }
    }

    @media  screen and (max-width:  760px){
        .header_section .overlay p{
         margin-left: 120px;
     } 
    }

    @media  screen and (max-width:  750px){
        .header_section{
            background-color:#F5F5FA; 
            
            
        }
        .header_section .overlay .small_content{
            color: white;
        }

        .overlay{
            background-color: unset;
        }
        .header_section .overlay .title{ 
         width: 50%;
         font-size: 17px;
         position: relative;  
         left: -105px;
         font-size: 18px;
        }
        .header_section .overlay .small_content{ 
         width: 50%;
         position: relative;
         top: -300px; 
         left: -80px; 
         text-align: left;
         font-size: 17px;
        }
        .header_section .overlay button{
            position: relative;
            top: -360px;
            left: -275px;
        }
        .header_section .img{
            /* width: 500px;
            height: 330px;
            position: absolute;
            right: 20px; 
            top: 230px;
            border-radius: 0px 30px 0px 30px; */
        }

        .header_section .small-container .overlay .title{
            position: relative;
            top: -100px !important;
        }

       

        .small-container{
            position: relative;
            margin-top: -0px !important;
        }

        .header_section .shape{
            display: none;
        }

        .header_section .arrow{
          margin-top: -200px !important;
        }

        .main_contents{
            background: #F5F5FA;
        }

        body{
            overflow-x: hidden;
        }
    }

    @media screen and (max-width: 710px) {
        .header_section .overlay button{
            /* position: relative;
            top: -360px;
            left: -275px; */
            margin-left: 20px;
        }

        .header_section .img{
            object-fit: cover;
        }
    }
    


    @media screen and (max-width: 700px) {
        .header_section .overlay button{
            /* position: relative;
            top: -360px;
            left: -275px; */
            margin-left: 40px;
        }
    }

    @media screen and (max-width: 667px) {
        .header_section .overlay button{
            /* position: relative;
            top: -360px;
            left: -275px; */
            margin-left: 70px;
        }
    }

    @media screen and (max-width: 631px) {
        .header_section .overlay button{
            /* position: relative;
            top: -360px;
            left: -275px; */
            margin-left: 20%;
        }
    }

    @media screen and (max-width: 445px) {
        .header_section .overlay button{
            /* position: relative;
            top: -360px;
            left: -275px; */

            margin-left: 300px;
            font-size: 11px;
        }

        .header_section .arrow{
          margin-left: -210px !important;
        }
    }
 
    

</style>