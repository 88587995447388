<template>

    <div class="main_contents " style="padding-top: 100px; margin-top: -100px; height: 100vh;">
        <!-- <Footer class="footer"></Footer> -->
        <div class="row" style="position: relative; top: -10px;">
            <div class="col-md-3" >
               
            
            </div>
        <div class="col-md-6">
            <div class="form_container">
                <div style="text-align: center; height: 13vh; margin-top: 20px; padding-left: 100px; padding-right: 100px;">
                    <h5 style="color: var(--title-color);">Contact Us</h5>
                    <p style="font-size: 11px;">For further questions, including partnership opportunities, please email info@datasystems.rw or contact using our contact form.</p>
                </div>
                <div class="inputs" style="height: 35vh;  padding-left: 30px; padding-right: 30px;">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">Full Name</label><br />
                                <input type="text" v-model="name" style="width: 100%;">
                            </div>
                            <div class="col-md-6">
                                <label for="">Email</label><br />
                                <input type="text" v-model="email" style="width: 100%;">
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12">
                                <label for="">How can we help you?</label><br />
                                <textarea v-model="message" cols="30" rows="10" placeholder="I want to say that..."></textarea>
                            </div>
                        </div>
                        <div style="text-align: center; margin-top: 15px;">
                            <button v-on:click="saveData" class="header_btn">SEND MESSAGE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3" >
               
            
        </div>
         
        
       </div> 
         
         <!-- <Footer class="footer"></Footer> -->
     
       <!-- <Footer class="footer"></Footer>  -->
        
    </div>

</template>

<script>

    import Footer from '../footer/Footer.vue'
    import axios  from 'axios'
    import Swall from 'sweetalert2'

    export default{
        data(){
            return{
            name: "",
            email: "",
            message: "", 
            
         }
    
        },

        components: {
            Footer
        },

        methods:{
         async saveData() {
            try{
               await axios.post("http://localhost:5000/contact",{
                  name: this.name,
                  email: this.email,
                  message: this.message,
               });
               this.name = "";
               this.email = "";
               this.message = "";
               Swall.fire({
                  title: "Done",
                  text: "Message sent successfully", 
                  icon: "success", 
                  confirmButtonColor: "#EB3F57",
                  confirmButtonText: "Continue"
               })
            } catch(err) {
               Swall.fire({
                  title: "Failed!",
                  text: "Internal server error", 
                  icon: "error", 
                  confirmButtonColor: "#EB3F57",
                  confirmButtonText: "Continue"
               })
               console.log(err)
            }
         }
      }
    }

</script>

<style scoped>

 
 
    .footer{
        margin-top: -50px;
    }

    .form_container{
                width: 632px;
                height: 343px;
                flex-shrink: 0;
                border-radius: 10px;
                background: var(--color-white-80, rgba(255, 255, 255, 0.80));
                box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.06), 0px 20px 26px 0px rgba(0, 0, 0, 0.05);
                backdrop-filter: blur(15px);
                margin-top: 20px;
                /* margin-left: 100px !important; */
            } 

            
            input{
                border: 0.5px solid #7878AB;
                border-radius: 6px; 
                height: 30px;
                background: #F5F5FA;
                font-size: 12px;
                padding-left: 10px;
                padding-right: 10px;
                outline: none;
            }
            textarea{
                border: 0.5px solid #7878AB;
                border-radius: 6px; 
                min-height: 70px;
                max-height: 70px;
                background: #F5F5FA;
                font-size: 12px;
                padding-left: 10px;
                padding-right: 10px;
                outline: none;
                width: 100%;
                padding-left: 20px;
                padding-top: 10px;
            }
            label{
                font-size: 11px;
                font-weight: 600;
            }

            button{
   display: inline-flex;
    padding: 6px 17px 6px 17px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background: var(--button-bg);
    box-shadow: var(--button-box-shadow);
    border: none;
    
    }
    button:hover{
    display: inline-flex;
    padding: 6px 17px 6px 17px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background: var(--title-color); 
    border: none;
    }

 
    @media screen and (max-width: 1440.5px) and (min-width: 760px) {
        .form_container{
            height: 403px;
            padding: 20px;
           
           
        }   
    }

    @media screen and (max-width: 1024.5px) {
        .form_container{
            margin-left: -50px !important;
        }
    }
        
    @media screen and (max-width: 768.5px) {
        .form_container{
            margin-left: -125px !important;
        }
    }

    @media screen and (max-width: 430.5px) {
        .form_container{
            width: 80% !important;
            margin-left: 40px !important;
            height: 64vh !important;
            padding-top: 1vh !important;
        }

        .form_container div p{
            width: 190% !important;
            margin-left: -60px !important;
        }
    }

    @media screen and (max-width: 375.5px) {
        .inputs{
            margin-top: 20px !important;
        }
       
    }

    @media screen and (max-width: 320.5px) {
        .form_container div h5{
            font-size: 15px;
            width: 150% !important;
            margin-left: -22px !important;
        }
 
        .form_container div p{
            font-size: 10px;
            width: 380% !important;
            margin-left: -80px !important;
        }
       
    }


</style>

