<template> 
  <Navbar/>
  <router-view style="margin-top: 7vh;" /> 
</template>

<script>
  import Navbar from'../src/components/navbar/Navbar.vue' 
  export default{
    components:{
      Navbar
    }
  }
</script>

<style>

:root{
    --title-color: #EB3F57;
    --button-bg:  linear-gradient(180deg, #EB3F57 0%, #893944 100%);
    --button-box-shadow: 10px 10px 20px 0px rgba(170, 170, 204, 0.50), 5px 5px 10px 0px rgba(170, 170, 204, 0.25);
    --other-title: #454654;
    --text-color: #000;
 }

 html{
  scroll-behavior: smooth;
 }

body{
  overflow-x: hidden; 
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

@media  screen and (min-width:797px){
      body{
        overflow-x: hidden;
      }
}

@media  screen and (max-width:   1110px){
  
}
    




</style>
