<template>
  <div class="about">
    <Header>
      <div class="header_section">
        <div class="small-container"> 
            <img class="img" src="../assets/res/Filter.png" alt="" srcset="">
            <img class="img" src="../assets/res/AdobeStock_27134790.png" style="opacity: 0.2;" alt="" srcset="">
          
            <div class="overlay">
              
             <p class="title" style="margin-left: 500px;">KNOW US IN DETAILS</p>
             
             <p class="small_content" style="width: 600px; position: relative; left: -40px;">
              At Data Systems Limited, we offer a wide range of services designed to fit your business needs. We specialize in e-government solutions and related services, as well as Customer Value Management (CVM) services, focusing on customer satisfaction and loyalty. With our dedicated team and innovative approach, we aim to deliver solutions that drive growth and success for our clients.
                 
             </p>
 
             <img class="arrow" v-on:click="scrollDown" src="../assets/res/Arrow 1 (1).png" style="width: 15px; height: 30px;  margin-top: 200px; margin-top: 205px; margin-left: -10px; cursor: pointer;" alt=""  srcset=""> 
            </div>
                <img class="shape"  src="../assets/res/Shape.png" alt="" style="width: 100%; height: 40px; margin-top: 550px;" srcset="">
        </div>
      </div>
    </Header>
    <Main_Contents />
  </div>
</template>

<script>
  import Header from'../components/header/Header.vue'
  import Main_Contents from'../components/about_page/main_contents/Contents.vue'

  export default{
    components: {
      Header,
      Main_Contents 
    },

    methods:{
      scrollDown(){
        window.scrollBy({ top: 600, left: 0, behavior: 'smooth' })
      }
    }
  }
</script>

<style scoped>
  @media screen and (max-width: 1514px) {
      /* .header_section .small-container .overlay .title{ 
        margin-left: 23% !important;
      }

      .header_section .small-container .overlay .small_content{
        margin-left: 32% !important;
      } */

  }

  @media screen and (max-width: 1188px) {
    .header_section .small-container .img{
      object-fit: cover;
    }
  }

  @media screen and (max-width: 1197px) {
     .header_section .small-container .overlay .title{ 
        margin-left: 15% !important;
      }

      .header_section .small-container .overlay .small_content{
        margin-left: 25% !important;
        
      } 

  }

  @media screen and (max-width: 1016px) {
     .header_section .small-container .overlay .title{ 
        margin-left: 10% !important;
      }

      .header_section .small-container .overlay .small_content{
        margin-left: 22.5% !important;
        
      } 

  }

  @media screen and (max-width: 898px) {
     .header_section .small-container .overlay .title{ 
        margin-left: 5% !important;
      }

      .header_section .small-container .overlay .small_content{
        margin-left: 18% !important;
        
      } 

  }

  
  @media screen and (max-width: 851px) {
     .header_section .small-container .overlay .title{ 
        margin-left: 2.5% !important;
      }

      .header_section .small-container .overlay .small_content{
        margin-left: 18% !important;
        
      } 

  }

  @media screen and (max-width: 798px) {
    .header_section .small-container .overlay .title{ 
       position: relative;
       top: -20px; 
      }
     .header_section .small-container .overlay .small_content{ 
       position: relative;
       top: -220px; 
      }

    .header_section .small-container .overlay .arrow{
      position: relative;
      top: -330px;
    }

  }

  @media screen and (max-width: 750px) {
    .header_section .small-container .overlay .title{ 
       position: relative;
       left: 5%; 
      
       text-align: left;
      }
     .header_section .small-container .overlay .small_content{ 
       position: relative;
       left: -10% !important;
       text-align: left;
       top: -250px;
       width: 400px !important;
      }

    .header_section .small-container .overlay .arrow{
      position: relative;
      top: 0px !important;

    }

  }

  .header_sectio .small-container .img{
    height: 300px !important;
  }
  
  @media screen and (max-width: 440px) {
    .header_section .small-container .overlay .small_content{  
       width: 350px !important;
      }
      .header_section .small-container .overlay .arrow{
      position: relative;
      left: 120px !important;

    }
    }


    @media screen and (max-width: 365px) {
      .header_section .small-container .overlay .small_content{ 
        width: 300px !important;
      }
    }
      
  
 

</style>