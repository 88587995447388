<template>
    <div class="footer-section">
        <nav style="margin-top: 100px; background: white; border-bottom: solid 0.5px #cfcfcf ;">
            <div class="row">
                <div class="col-md-6"> 
                </div>
                <div class="col-md-6">
                    <div>
                        <router-link v-bind:to="{ name: 'home' }">Home</router-link>
                        <router-link v-bind:to="{ name: 'about' }">About Us</router-link>
                        <router-link v-bind:to="{ name: 'career' }">Careers</router-link>
                        <router-link v-bind:to="{ name: 'contact' }">Contact Us</router-link>
                    </div>
                </div>
            </div>
        </nav> 
        <nav style="  background: white; height: 30px;">
            <div class="row">
                <div class="col-md-6 footer-contents_1" style="padding-left: 120px;"> 
                    <p style="font-size: 11px; position:relative; top: 7px;">© 2023 data systems limited All Rights Reserved</p>
                </div>
                <div class="col-md-6 footer-contents_2" style="text-align: right; padding-right: 70px;">
                  <p style="font-size: 11px; position:relative; top: 7px;">10 KN 4 Ave, Kigali, Rwanda, MAKUZA PLAZA Block A, 11th floor</p>
                </div>
            </div>
        </nav>
    </div>
</template>

<style scoped>

 
nav .col-md-6 div{
    display: flex;
}
nav .col-md-6 div a{
      color: #fff;  
      font-size: 15px;
      line-height: 25px;  
      margin: 0 1rem;
      display: flex;
      
      margin-top: 12px;
      cursor: pointer;
      display: flex;
   } 
  
   nav .col-md-6 div a{
      color: var(--text-color);
      text-decoration: none;
   }

   /* @media  screen and (max-width:  767px){
    .form_containerr{
        position: absolute;
        top: 500px;
    }
    } */
    @media screen and (max-width: 767px){
    .footer-section{ 
      margin-top: -150px !important;
   }
}  
    @media screen and (max-width: 719px){
    .footer-section{ 
      margin-top: 140px !important;
   }

   .footer-contents_1 p .footer-contents_1 p{
     font-size: 8px !important;
     
   }

   .footer-contents_2 p{
    font-size: 8px !important;
    position: relative;
    top: -20px !important;
   }
}

@media screen and (max-width: 642px){
    .footer-contents_2{
        display: none;
    }
}



</style>