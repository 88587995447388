<template>
  <nav class="w3-card">
    <div class="row">
      <div class="col-md-6">
        <img class="logo" src="../../assets/res/WhatsApp Image 2023-07-05 at 12.27 1.png" alt="" srcset="">
      </div>
      <div class="col-md-6">
        <div>
          <router-link :to="{ name: 'home' }">Home</router-link>
          <router-link :to="{ name: 'about' }">About Us</router-link>
          <router-link :to="{ name: 'career' }">Careers</router-link>
          <router-link :to="{ name: 'contact' }">Contact Us</router-link>
          <router-link :to="{ name: 'contact' }" class="button">CONTACT US</router-link> 

          <!-- <button style="width: 50px; position: relative; background: none; box-shadow: none;" class="button"><img src="../../assets/res/menu.png" style="width: 40px; position: absolute; left: 0px;" alt="" srcset=""></button>  -->

            
      
        </div>
        <nav class="w3-card w3-animate-top" ref="manu">
          <router-link :to="{ name: 'home' }">Home</router-link>
          <router-link :to="{ name: 'about' }">About Us</router-link>
          <router-link :to="{ name: 'career' }">Careers</router-link>
          <router-link :to="{ name: 'contact' }">Contact Us</router-link>
          <a @click="closeMenu">
            <img src="../../assets/res/cross.png" alt="" srcset="">
          </a>
        </nav>
        <a class="menu" v-on:click="toggleMenu"><img ref="menuImage" src="../../assets/res/menu.png" style="position: absolute; right: 80px; top:  -3px;"  alt="" srcset=""></a>
      </div>
    </div>
  </nav> 
</template>

<script>
    export default{
      data(){
        return{ 
          show: false
        }
      },

      methods: {  
        closeMenu(){
          this.$refs.manu.style.display = 'none'
          
        }, 
        toggleMenu(){
          this.$refs.manu.style.display = 'block'
          this.show = true

        }
      }
    }
</script>

<style scoped> 

.menu{
  cursor: pointer;
  display: none;

}

nav{
    width: 100%;
    height: 7vh;  
    position: fixed;
    z-index: 1;
    background: white;
    margin-top: -7vh;
    
}

.col-md-6{
  position: relative;
}

.col-md-6 img{
    width: 35px;  
    flex-shrink: 0;
    margin-top: 6px;
    margin-left: 130px;
 }

 nav .col-md-6 div{
    display: flex;
    flex-direction: row;
 }

 nav .col-md-6 div a{
    color: #fff;  
    font-size: 15px;
    line-height: 25px;  
    margin: 0 1rem;
    margin-top: 10px;
    cursor: pointer;
 } 

 nav .col-md-6 div a{
    color: var(--text-color);
    text-decoration: none;
 }

 .router-link-exact-active {
    color: #EB3F57;
  }
   
 nav .col-md-6 div .button{
    display: inline-flex;
    padding: 4px 17px 4px 17px;
    color: #fff;
    font-size: 12px;
    border-radius: 32px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    background: var(--button-bg);
    box-shadow: var(--button-box-shadow);
    border: none;
    margin-top: 5px;
    margin-left: 20px;
 }

 nav .col-md-6 div .button:hover{
    display: inline-flex;
    padding: 4px 17px 4px 17px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background: var(--title-color); 
    border: none;
    margin-top: 5px;
    margin-left: 20px;
 }



 nav .row .col-md-6  nav{
  position: absolute;
  top: 110px;
  left: 0px;
  background:#F5F5FA; 
  border-left: 5px solid #EB3F57;
  width: 100%;
  height: auto;
  padding: 20px;
  display: none;
  
 }

 nav .row .col-md-6 nav a{
  display: block;
  color: var(--text-color);
  text-decoration: none;
 }

 nav .row .col-md-6 nav img{
  position: absolute;
  right: 70px;
  top: 10px;
  width: 25px;
  cursor: pointer;
 }




 @media  screen and (max-width: 1184px) {


    nav .row .col-md-6 div{
    display: none; 
    }

    nav .row .col-md-6 .menu{
      display: block;
      background: #EB3F57;
      position: relative;
      left: -20px;
      margin-top: 5px;
    }

   
  }

  @media  screen and (max-width: 767px){
    nav .row .col-md-6 .menu{
      margin-top: -38px;
    }

    nav .row .col-md-6  nav{
      margin-top: -100px;
    }

    
  }


  @media  screen and (max-width:   797px){
    body{
      width: 100%;
    }
  }

  @media  screen and (max-width: 550px){
     
    nav .row .col-md-6 .menu{
      position: relative;
      z-index: 1;
      left: 58px !important;
    }
    nav .row .col-md-6 .logo{
      position: relative;
      left: -100px !important;
    } 
  }







</style>
