<template>
    <div class="flowing-section" style="background: #F5F5FA;">
        <div class="">
            <div class="parteners_section">
                <div  style="padding-left: 150px; padding-right: 150px;">
                    <div class="title" style=" width: 407px; height: 87px; ">
                        <h2 style="font-size: 27px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654;">join our team of professionals</h2>
                        <img src="../../assets/res/Arrow2.png" style="stroke-width: 3px; width: 43px; margin-left: -0px; margin-top: -10px;" alt="" srcset="">
                    </div>
    
                    
    
    
                    
                    
                    
                </div>
                 
            </div>
        </div>

        <!-- section 4 -->


        <div style="padding-left: 150px; padding-right: 150px;">
            <div class="containerr my-5">
                <div class="divs">
                    <div class="row">
                        <div class="col-md-10" style="text-align: left; padding-left: 25px;  ">
                            <p style="color: var(--title-color); font-size: 14px; margin-top: 12px;">No available position for now</p>
                            
                        </div>
                        <div class="col-md-2">
                            <p style="color: var(--title-color); font-size: 13px; margin-top: 15px; margin-left: 25px;"><a href="https://www.linkedin.com/company/data-systems-limited/jobs/" class="text-decoration-none">Keep an aye </a><span><img src="res/Arrow2.png" width="20px" alt="" srcset=""></span></p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer class="footer"></Footer>
    </div>
</template>


<script>
 import Footer from'../footer/Footer.vue'

 export default{
    components: {
        Footer
    }
 }

</script>
<!-- #F5F5FA -->


<style scoped>
.flowing-section
{
    overflow-y: scroll;
    overflow-x: hidden;
}


.flowing-section::-webkit-scrollbar{
    display: none;
}
.containerr{
    width: 100%;
    min-height: 5vh; 
    box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05); 
    text-align: center;
    color: white;
    margin-top: 20px; 
}



.containerr .divs{
    height: 7vh;
    background: white;
    width: 100%;
    border-bottom: .02px #cfcfcf solid;
}

.containerr .divs .row .col-md-10{
    position: relative;
}

.containerr .divs .row .col-md-10 a{
    position: absolute;
    right: 20px;
    display: none;
}

.parteners_section{
    padding-top: 100px;
}


@media screen and (max-width: 1440.5px) {
    
    .containerr .divs .row  p{ 
        position: relative;
        /* top: 5px !important; */
        top: 18px;
    }
}

@media screen and (max-width: 1279.5px) {
    .containerr .divs .row  p{ 
        position: relative;
        /* top: 5px !important; */
        top: 25px;
    }
}

@media screen and (max-width: 1253.5px) {
    .containerr .divs .row .col-md-10 p{ 
        margin-left: 20px !important;
    }

    .containerr .divs .row .col-md-2 p{ 
        margin-left: -20px !important;
    }
}

@media screen and (max-width: 912.5px) {
 

    .containerr .divs .row .col-md-2 p{ 
        margin-left: -40px !important;
    }
}

@media screen and (max-width: 767.5px) {
    .containerr .divs .row .col-md-2{
        display: none;
    }

    .containerr .divs .row .col-md-10 a{ 
        display: block;
        margin-top: -25px !important;
    }
}

@media screen and (max-width: 766.5px) {
     .footer{
        margin-top: 120px !important;
     }
}

@media screen and (max-width: 613.5px) {

    .parteners_section div .title{
        margin-left: -24% !important;
    }
     .containerr{   
        width: 150%;
        margin-left: -24% !important;
     }
}

@media screen and (max-width: 545.5px) {

.parteners_section div .title{
    margin-left: -50% !important;
}
 .containerr{   
    width: 200%;
    margin-left: -50% !important;
 }
}

@media screen and (max-width: 453.5px) {

.parteners_section div .title{
    margin-left: -70% !important;
}
 .containerr{   
    width: 265%;
    margin-left: -85% !important;
 }
}

@media screen and (max-width: 414.5px) {

.parteners_section div .title{
    margin-left: -110% !important;
}
 .containerr{   
    width: 325%;
    margin-left: -115% !important;
 }
}

@media screen and (max-width: 395.5px) {

.parteners_section div .title{
    margin-left: -120% !important;
}
 .containerr{   
    width: 345%;
    margin-left: -125% !important;
 }
}

@media screen and (max-height: 875.5px) {

    .containerr .divs .row .col-md-10 p{ 
        margin-top: -3px !important;
    }

    .containerr .divs .row .col-md-2 p{ 
        margin-top: -3px !important;
    }
}

@media screen and (max-height: 662.5px) {

.containerr .divs .row .col-md-10 p{ 
    margin-top: -6px !important;
}

.containerr .divs .row .col-md-2 p{ 
    margin-top: -6px !important;
}
}

@media screen and (max-height: 600.5px) {

.containerr .divs .row .col-md-10 p{ 
    margin-top: -9px !important;
}

.containerr .divs .row .col-md-2 p{ 
    margin-top: -9px !important;
}
}

@media screen and (max-height: 533.5px) {

.containerr .divs .row .col-md-10 p{ 
    margin-top: -13px !important;
}

.containerr .divs .row .col-md-2 p{ 
    margin-top: -13px !important;
}
}








</style>