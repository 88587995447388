<template>
    <div class="ty" style="background: #F5F5FA;;">  
       <div class="contents-containerr">
           <Contents></Contents>
       </div>
    </div>
   </template>
   
   <script> 
       import Contents from '@/components/contact_page/Contents.vue';
       export default{
           components: { 
               Contents
           }
       }
   </script>
   
   <style scoped>
   
     
       .contents-containerr{
          margin-top: 80px; 
       }
   
       .ty{
           min-height: 100vh;
           overflow-y: hidden;
       }
   
       .ty::-webkit-scrollbar{
           display: none;
       }
   </style>