<template>

   <div class="container" style="padding-left: 170px; padding-right: 170px;" id="form">
               <div class="form_containerr">
                   <div class="row">
                       <div class="col-md-7">
                               <div>
                                   <h6>Say Hi!</h6>
                                   <p>We'd like to talk to you.</p>
                                   <div class="form">
                                       <div class="form-groupp">
                                           <label for="">My name is</label><br />
                                           <input class="inputs" type="text" v-model="name" required>
                                       </div>
                                       <div class="form-groupp">
                                           <label for="">My email is</label><br />
                                           <input type="email" class="inputs" v-model="email">
                                       </div>
                                       <div class="form-groupp">
                                           <label for="">Your message</label><br />
                                           <textarea class="inputs"  cols="30" rows="3" v-model="message"></textarea>
                                       </div>
                                       <button @click="saveData">SEND MESSAGE</button>
                                   </div>
                               </div>
                       </div>
                       <div class="col-md-5" style="border-radius: 0px 8px 8px 0px; ">
                           <h6>CONTACT INFORMATION</h6>
                           <p>Fill up the form and our Team will get back to you within 24 hours.</p>
                           <p style="margin-top: 190px; margin-left: 60px; font-weight: 250px; font-size: 9px;"><img src="res/Phone Icon.png" style="width: 10px; height: 10px;" alt="" srcset=""> (+250)781464616, (+250)791918007</p>
                           <p style="margin-top: 220px; margin-left: 60px; font-weight: 250px; font-size: 9px;"><img src="res/Envelope Icon.png" style="width: 10px; height: 10px;" alt="" srcset=""> info@datasystems.rw</p>
                           <p style="margin-top: 250px; margin-left: 60px; font-weight: 250px; font-size: 9px;"><img src="res/Map Marker Icon.png" style="width: 10px; height: 10px;" alt="" srcset=""> MAKUZA PLAZZA, 11TH FLOOR</p>
                           <div style="position: absolute; display: flex; margin-left: 74px; margin-top: 300px;">
                               <a href=""><img src="../../../assets/icons/Vector.png" style="width: 15px; border-radius: 0;" alt="" srcset=""></a>
                               <a href=""><img src="../../../assets/icons/Vector2.png" style="width: 15px; border-radius: 0;" alt="" srcset=""></a> 
                               <a href="https://www.linkedin.com/company/data-systems-limited"><img src="../../../assets/icons/Vector3.png" style="width: 15px; border-radius: 0;" alt="" srcset=""></a>
                           </div>
                           <img class="form-bg" src="../../../assets/res/Background.png" style="border-radius: 0px 8px 8px 0px; background: #F5F5FA;" alt="" srcset="">
                           
                       </div>
                     
                   </div>
               </div>
              
           </div>
   
   </template>
   
   <script>
      import axios  from 'axios'
      import Swall from 'sweetalert2'
      
   
      export default{
         data(){
            return{
               name: "",
               email: "",
               message: "", 
               
            }
   
       
         },
   
         methods:{
            async saveData() {
               try{
                  await axios.post("http://localhost:5000/contact",{
                     name: this.name,
                     email: this.email,
                     message: this.message,
                  });
                  this.name = "";
                  this.email = "";
                  this.message = "";
                  Swall.fire({
                     title: "Done",
                     text: "Message sent successfully", 
                     icon: "success", 
                     confirmButtonColor: "#EB3F57",
                     confirmButtonText: "Continue"
                  })
               } catch(err) {
                  Swall.fire({
                     title: "Failed!",
                     text: "Internal server error", 
                     icon: "error", 
                     confirmButtonColor: "#EB3F57",
                     confirmButtonText: "Continue"
                  })
                  console.log(err)
               }
            }
         }
   
      }
   </script>
   
   <style scoped>
      .container .form_containerr{
         width: 100%;
         margin-top: 150px !important;
         height: 409px; 
         border-radius: 10px 0px 0px 10px;
         background: #FFF; 
         box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
         backdrop-filter: blur(15px);
      /* margin-top: 800px; */
    
   }
   
   .container{
      border-radius: 8px; 
   }
   .container .form_containerr .row .col-md-7{ 
      height: 409px;
      border-radius: 8px 0px 0px 8px;
      margin-left: 15px;
      padding-left: 30px;
      padding-top: 40px;
   }
   
   .container .form_containerr .row .col-md-7 h6{
      font-size: 24px;
      font-weight: 600;
      color: var(--title-color);
   }
   
   .container .form_containerr .row .col-md-7 p{
      font-size: 14px;
      font-weight: 350;
      color: var(--other-title);
   }
   
   .container .form_containerr .row .col-md-7 .form label{
      font-size: 13px;
   }
   
   .container .form_containerr .row .col-md-7 input{
      border: 0.5px solid #7878AB;
      border-radius: 6px; 
      width: 95%;
      height: 30px;
      background: #F5F5FA;
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
   }
   
   .container .form_containerr .row .col-md-7 button{
      display: inline-flex;
      padding: 6px 17px 6px 17px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      background: var(--button-bg);
      box-shadow: var(--button-box-shadow);
      border: none;
      margin-top: 20px;
      margin-left: 185px;
   }
   
   .container .form_containerr .row .col-md-7 button:hover{
    
      background: var(--title-color); 
     
   }
   
   .container .form_containerr .row .col-md-7 textarea{
      border: 0.5px solid #7878AB;
      border-radius: 6px; 
      width: 95%;
      min-height: 60px;
      max-height: 60px;
      padding-left: 10px;
      padding-right: 10px;
      background: #F5F5FA;
      font-size: 12px;
      outline: none;
   }
   
   
   .container .form_containerr .row .col-md-5{
      width: 409px; 
      border-radius: 0px 8px 8px 0px; 
      margin-left: -30px;
      padding: 0;
   }
   
   .container .form_containerr .row .col-md-5 img{
      width: 313.5px;
      margin-left: 40px;
       border-radius: 0px 8px 8px 0px;  
   }
   
   .container .form_containerr .row .col-md-5 h6{
      position: absolute;
      color: white;
      margin-left: 110px;
      margin-top: 110px;
      font-size: 14px;
      font-weight: 600;
   }
   
   .container .form_containerr .row .col-md-5 p{
      position: absolute;
      color: white;
      margin-left: 110px;
      margin-top: 140px;
      font-size: 10px;
      font-weight: 350;
   }
   
   @media screen and (max-width: 1225px){
      .container .form_containerr .row .col-md-7 button{
         font-size: 8px;
      }
   }
   
   
   
   @media screen and (max-width: 1153px) {
      .form_containerr .row  .col-md-5{
         display: none;
         border-radius:10px 10px 10px 10px !important;
      }
   
      .form_containerr .row .col-md-7{
         position: absolute;
         position: relative;
      }
      .form_containerr .row .col-md-7 .inputs{
         position: relative; 
         width: 169% !important;
      }
   }
   
   @media screen and (max-width: 1117px){
      .container .form_containerr .row .col-md-7 button{
         padding: 3px 14px 3px 14px;
      }
   }
   
   @media screen and (max-width: 1068px){
      .container{
         padding-left: 10px !important;
         padding-right: 10px !important;
      }
   }
   
   @media screen and (max-width: 768px){
      .form_containerr .row .col-md-7 .inputs{
         position: relative; 
         width: 90% !important;
      }
   }
   
   @media only screen and (width:  768px){
      .form .form-groupp .inputs{ 
         width: 230% !important;
      }
   }
   
   @media  screen and (max-width:  767px){
    
      .form_containerr{
         position: relative;
         top: -800px;
      }
   }
   
   @media screen and (max-width: 719px){
      .container{
         position: relative;
         top: 100px;
      }
   }   
   
   @media screen and (max-width: 715px){
      .form_containerr{
         height: 400px !important;
      }
   }   
   
   
   @media screen and (max-width: 554px){   
      
      .container{
         position: relative;
         top: 200px !important;
      }
   }
   
   
   @media screen and (max-width: 492px){   
      .container{
         padding-left: 0px !important;
         padding-right: 0px !important;
      }
      
      .container .form_containerr .row .col-md-7 button{
         margin-left: 0px;
      }
   }
   
   
   
   
   </style>