<template>
    <div class="main_contents" id="main">
       <div class="row ">
           <div class="col-md-6" style="padding-left: 150px; padding-right: 70px;">
               <div class="content-title-arrow" style=" width: 107px; height: 87px; ">
                   <h2 style="font-size: 27px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654;">WHO WE ARE</h2>
                   <img src="../../../assets/res/Arrow2.png" style="stroke-width: 3px; width: 43px; margin-left: -0px; margin-top: -10px;" alt="" srcset="">
               </div>
               <div class="software_Outsourcing_section my-4">
                   <h6>SOFTWARE OUTSOURCING COMPANY</h6>
                   <p class="my-5" style="text-align: justify;">Data Systems Limited is a software outsourcing 
                       company based in Rwanda that specialized in solving
                        complex business challenges using thought processes 
                        and information technology. We offer custom software 
                        development services on diverse and modern technology 
                        platforms, like Microsoft, Google Firebase, JavaScript,
                         React.js, React-native, Node.js, and Flutter. With rich 
                         and varied experience in software development and stringent 
                         quality standards, we offer the utmost qualitative, on-time, 
                         and cost-effective software solutions. We serve clients across 
                         the industries and the globe with Offices in Rwanda, Ghana, and Cameroun.</p>
                         <button class="my-3">GET STARTED</button>
               </div>
           </div>
           <div class="col-md-6">
               <img class="ceo" src="../../../assets/res/AdobeStock_170801444 1.png" style="width: 100%; margin-left: -0px;" alt="" srcset="">
           </div>
       </div>

       <!-- section 2 -->

       <div class="row my-1">
           <div class="col-md-6 my-5">
               <img src="../../../assets/res/AdobeStock_227421922 1.png" class="my-5 ceo" style="width: 100%; margin-left: -0px;" alt="" srcset="">
           </div>
           <div class="col-md-6" style="padding-right: 150px;">
               
               <div class="software_Outsourcing_section section-2 my-4"> 
                   <div class="row">
                       <div class="col-md-4   my-5">
                           <img class="title-img" src="../../../assets/res/target 1.png" style="position: relative; left: 80px; top: 135px; width: 60px;" alt="" srcset="">
                          
                       </div> 
                       <div class="col-md-8 my-5">
                           <div class="section-2-title" style="width: 147px; height: 87px; text-align:end; margin-left: 165px;">
                               <h2 style="font-size: 27px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654;">WE BUILD FUTURE</h2>
                               <img src="../../../assets/res/Arrow 2.png" style="stroke-width: 3px; width: 43px; margin-left: -0px; margin-top: -10px;" alt="" srcset="">
                           </div>
                          <div class="our-speciality">
                           <h5 style="font-size: 15px; position: relative; top: 35px; font-weight: 700;">Our Speciality</h5>
                           <p class="my-5" style="text-align: justify;"> 
                               our specialty lies in the seamless integration of technology and innovation to create tailored software solutions that empower businesses. With a deep understanding of modern technology platforms and a commitment to delivering quality, on-time, and cost-effective solutions.</p>
                          </div>

                               
                              
                       </div>
                   </div>
                   <div class="row" style="margin-top: -50px;">
                       <div class="col-md-4   my-5">
                           <img class="title-img" src="../../../assets/res/pathway 1.png" style="position: relative; left: 80px; top: -60px; width: 60px;" alt="" srcset="">
                          
                       </div> 
                       <div class="col-md-8 our-approach">
                           <h5 style="font-size: 15px; position: relative; top: -30px; font-weight: 700;">Our Approach</h5>
                               <p  style="text-align: justify;position: relative; top: -25px;"> 
                                   As a result, we offer our clients with the development of CRM cloud systems, bespoke website development and design, project and R&D management, custom development, and system integration.</p>
                       </div>
                   </div>
                    
               </div>
           </div>
       
       </div>

       <!-- section 3 -->
       <div class="parteners_section my-1  ">
           <div class="partener-contents-container"  style="padding-left: 150px; padding-right: 150px;">
               <div style=" width: 107px; height: 87px; ">
                   <h2 style="font-size: 27px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654;">OUR PARTENERS</h2>
                   <img src="../../../assets/res/Arrow2.png" style="stroke-width: 3px; width: 43px; margin-left: -0px; margin-top: -10px;" alt="" srcset="">
               </div>
           <div class="partener-contents">
               <div class="row my-5">
                   <div class="col-md-3">
                       <div>
                           <img src="../../../assets/res/oracle.png" width="70px" alt="" srcset="">
                       </div>
                   </div>
                   <div class="col-md-3">
                       <div style="margin-left: 30px; margin-top: 50px;">
                           <img src="../../../assets/res/lenovo.png" width="70px" alt="" srcset="">
                       </div>
                   </div>
                   <div class="col-md-3">
                       <div class="hp" style="margin-left: 60px; margin-top: 50px;">
                           <img src="../../../assets/res/hp.png" width="70px" alt="" srcset="">
                       </div>
                   </div>
                   <div class="col-md-3">
                       <div class="dell" style="margin-left: 90px;">
                           <img src="../../../assets/res/dell.png" width="70px" alt="" srcset="">
                       </div>
                   </div>
               </div>


               <div class="row my-5">
                   <div class="col-md-3">
                       <div class="micro">
                           <img  src="../../../assets/res/microsoft.png" width="70px" alt="" srcset="">
                       </div>
                   </div>
                   <div class="col-md-3">
                       <div class="gira-ict"  style="margin-left: 30px; margin-top: 50px;">
                           <img src="../../../assets/res/WhatsApp Image 2023-07-05 at 12.28 (1).png" width="70px" alt="" srcset="">
                       </div>
                   </div>
                   <div class="col-md-3">
                       <div class="eshuri"  style="margin-left: 60px; margin-top: 50px;">
                           <img src="../../../assets/res/WhatsApp Image 2023-07-05 at 12.28.png" width="70px" alt="" srcset="">
                       </div>
                   </div>
                   <div class="col-md-3">
                       <div  class="sap" style="margin-left: 90px;">
                           <img src="../../../assets/res/sap.png" width="70px" alt="" srcset="">
                       </div>
                   </div>
               </div>
           </div>  
               <Form></Form> 
           </div>
           
            
       </div>

       
       <Footer></Footer>
   </div>
</template>

<script>
   import Form from '../form/FormComponent.vue'
   import Footer from'../../footer/Footer.vue'
   export default{
       components:{
           Form,
           Footer
       }
   }
</script>

<style scoped>


.main_contents{
  width: 100%;
  padding-top: 70px;
  background: #F5F5FA;
  min-height: 300px;  
  overflow-y: hidden;
}

.main_contents::-webkit-scrollbar{
   display: none;
}

.software_Outsourcing_section h6{
  font-size: 25px;
  color: var(--title-color);
  font-weight: 600;
}

.software_Outsourcing_section p{
  font-size: 14px;
}

.software_Outsourcing_section button{
  display: inline-flex;
   padding: 6px 17px 6px 17px;
   color: #fff;
   font-size: 12px;
   font-weight: 600;
   justify-content: center;
   align-items: center;
   border-radius: 32px;
   background: var(--button-bg);
   box-shadow: var(--button-box-shadow);
   border: none;
}

.software_Outsourcing_section button:hover{
  display: inline-flex;
  padding: 6px 17px 6px 17px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: var(--title-color); 
  border: none;
}

.parteners_section .row .col-md-3 div{
  justify-content: center;
  display: flex;
  align-items: center; 
  width: 151px;
  height: 89px;
  border-radius: 5px;
  border: 0.5px solid rgba(140, 57, 69, 0.50);
}


@media screen and (max-width: 1440.5px) {
   .section-2-title{
       margin-left: 61% !important;
   }
}


@media  screen and (max-width:  1159px){
   .our-speciality{
       position: relative; 
       left: 50px;
   }

   .our-approach{
       position: relative; 
       left: 50px; 
   }


}


@media  screen and (max-width:  1068px){

   .section-2-title{
       position: relative;
       left: -50px;
   }

}



@media  screen and (max-width:  1022px){
   .software_Outsourcing_section h6{
       font-size: 20px;
   }


}

@media  screen and (max-width:  957px){ 
   .title-img{
       display: none;

   }
   .our-speciality{
       position: relative;
       padding: 5px;
       left: -50px;
       width: 310px !important;
   }

   .our-approach{
       position: relative;
       padding: 5px;
       left: -50px;
       width: 350px !important;
   }

}

@media  screen and (max-width:  890px){
   .software_Outsourcing_section{
       margin-left: -50px; 
       font-size: 16px;
   }

   .content-title-arrow{
       margin-left: -50px; 
   }
}
@media  screen and (max-width:  797px){
   .software_Outsourcing_section{
       margin-left: -80px; 
       font-size: 16px;
   }

   .content-title-arrow{
       margin-left: -80px; 
   }

   .section-2{
       margin-left: 60px; 
       font-size: 16px;
       padding-right: -40px; 
       position: relative;
       top: -100px;
       
       width: 100%;
   }


   .section-2-title{
       margin-left: 73% !important;
   }

   .title-img{
       position: relative;
       top: 200px;
   }
}

@media  screen and (max-width:  768.5px){
   .ceo{
       width: 70% !important;
       position: relative;
       left: 50px;
   }



   .software_Outsourcing_sectio button{
       padding: 4px 15px 4px 15px; 
   }
}

@media only screen and (width:  768px){
   .section-2-title{
       margin-left: 80px !important;
   }

   .our-speciality{
       margin-left: -80px;
   }

   .our-approach{ 
       margin-left: -70px;

   }

   .our-approach p{
       width: 182% !important;

   }

   .partener-contents-container{
       margin-left: -120px !important;
   }
}

@media  screen and (max-width:  767px){
   .our-approach{ 
       position: relative;
       top: -50px;
   }

   .parteners_section{
       position: relative;
       top: -250px;
   }

}

@media  screen and (max-width:  767px){
   /* .main_contents{
       height: max-content;
   } */

   .parteners_section{
       min-height: 480px;
       max-height: 700px;
   }
   .software_Outsourcing_section{
       margin-left: -80px; 
       font-size: 16px;
   }

   .our-speciality{
       position: relative;
       left: 0px;
       width: 100% !important;
   }

   .our-approach{
       position: relative;
       left: 0px;
       width: 100% !important; 
   }

   .content-title-arrow{
       margin-left: -80px; 
   }

   .section-2{
       margin-left: 60px; 
       font-size: 16px;
       padding-right: -40px; 
       position: relative;
       top: -100px;
       width: 100%;
   }


   .section-2-title{
       margin-left: 73% !important;
   }

   .title-img{
       
       display: none;
   }

   .partener-contents-container{
       padding-left: 50px !important;
       padding-right: 50px !important; 
       margin-top: 50px !important;
   }

   .parteners_section .row .col-md-3 div{
       width: 130px;
       height: 79px;
       display: flex !important;
       margin-left: 5px !important;
   }

   .parteners_section .row .col-md-3 .micro, .gira-ict{
       position: relative;
       left: 340px; 
       top: -515px;
   }

   .parteners_section .row .col-md-3 .eshuri, .sap{
       position: relative;
       left: 510px; 
       top: -774px;
   }

   .parteners_section .row .col-md-3 .sap{
      margin-top: 50px;
   }
   .parteners_section .row .col-md-3 .hp, .dell{
       position: relative;
       left: 170px; 
       top: -260px;
   }

   
   .parteners_section .row .col-md-3 .dell{
       margin-top: 50px;
   }
}



@media screen and (max-width: 720px) {
   .parteners_section .row .col-md-3 .sap{
       position: relative;
       top: -640px;
       left: 100px;
   }

   .parteners_section .row .col-md-3 .eshuri{
       position: relative;
       top: -510px;
       left: 270px;
   }
}



@media screen and (max-width: 716px) {
   .main_contents{
       height: 3100px !important; 
   }
}

@media screen and (max-width: 620px) {

    .section-2-title{
       margin-left: 67% !important;
   }
}


@media screen and (max-width: 555px) {
   .parteners_section .row .col-md-3 .sap{
       position: relative;
       top:  -650px; 
       left: 0px;
   }

   .parteners_section .row .col-md-3 .eshuri{
       position: relative;
       top: -520px; 
       left: 170px;
   }

   .parteners_section .row .col-md-3 .micro{
       position: relative;
       left: 170px;
       top: -140px;
   } 
   .parteners_section .row .col-md-3 .gira-ict{
       position: relative;
       top: -270px;
       left: 0px;
   }
}

@media screen and (max-width: 500px) {

.section-2-title{
  margin-left: 62% !important;
}
}

@media screen and (max-width: 450px) {

.section-2-title{
  margin-left: 56% !important;
}
}

@media screen and (max-width: 420px) {

.section-2-title{
  margin-left: 50% !important;
}


}











</style>