<template>
    <div class="my-5" style="margin-top: 120px !important;">
        <div class="parteners_section my-5">
            <div  style="padding-left: 150px; padding-right: 150px;">
                <div class="our-products" style=" width: 107px; height: 87px; ">
                    <h2 style="font-size: 27px; font-style: normal; font-weight: 350; text-transform: uppercase; color: #454654;">OUR PRODUCTS</h2>
                    <img src="../../../assets/res/Arrow2.png" style="stroke-width: 3px; width: 43px; margin-left: -0px; margin-top: -10px;" alt="" srcset="">
                </div>

                


                
                
                
            </div>
             
        </div>
    </div>
<div class="container" style="padding-left: 170px; padding-right: 170px;">
        <div class="form_containerrr" style="background: none; box-shadow: none;">
            <div class="container">
                <div class="container">
                    <div class="container">
                        <div class="container">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6">
                                        <a href="https://giraict.rw/" class="text-decoration-none">
                                            <div class="divv">
                                            <img src="../../../assets/res/WhatsApp Image 2023-07-05 at 12.28 (1).png" style="width: 100px; position: relative; top: 30px;" alt="" srcset="">
                                            <h6>Gira ICT</h6>
                                            <p>Explore our online store, <span style="color: skyblue;">www.giraict.rw</span> , to find a wide range of quality electronic devices and IT equipment. We source products from reputable suppliers, ensuring reliability and performance.</p>
                                        </div>
                                        </a>
                                    </div>
                                    <div class="col-md-6">
                                       <a href="https://eshuri.org/" class="text-decoration-none">
                                        <div class="divvv">
                                            <img src="../../../assets/res/WhatsApp Image 2023-07-05 at 12.28.png" style="width: 100px; position: relative; top: -10px;" alt="" srcset="">
                                            <h6>eShuri</h6>
                                            <p>Our app and website offer personalized learning with expert tutors and mentors using AI algorithms. Choose one-on-one or group sessions, access video lessons, quizzes, and study plans. Educators are vetted, and progress can be tracked on our dashboard. Explore now!</p>
                                        </div>
                                       </a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <h6></h6>
                    </div>
                </div>
                
            </div>
            
        </div>
        <div class="work-together">
            <h6 style="text-transform: uppercase; font-size: 19px; color: var(--title-color); margin-left: 220px; font-weight: 700; margin-top: 70px;">Sounds Great?! Let’s work together!</h6>
            <button class="header_btn" style="margin-left: 350px; position: relative; top: 20px;">CONTACT US</button>
        </div>
       
    </div>
    <Footer class="footer"></Footer>
</template>

<script>
import Footer from'../../footer/Footer.vue'
export default{
    components: {
        Footer
    }
}
</script>

<style scoped>
.container .form_containerrr .container .row .col-md-6 .divv{
    width: 100%;
    height: 320px;
    background: #454654;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 35px;
    text-align: center;
    color: white;
}
.container .form_containerrr .container .row .col-md-6 .divv p{
    font-size: 12px;
    margin-top: 80px;
}
.container .form_containerrr .container .row .col-md-6 .divv h6{
    position: relative;
    top: 70px;
}

.container .form_containerrr .container .row .col-md-6 .divvv p{
    font-size: 12px;
}
.container .form_containerrr .container .row .col-md-6 .divvv{
    width: 100%;
    height: 50vh;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: black;
    padding: 35px;
    text-align: center;
}

button{
display: inline-flex;
padding: 6px 17px 6px 17px;
color: #fff;
font-size: 12px;
font-weight: 600;
justify-content: center;
align-items: center;
border-radius: 32px;
background: var(--button-bg);
box-shadow: var(--button-box-shadow);
border: none;
}
button:hover{
display: inline-flex;
padding: 6px 17px 6px 17px;
color: #fff;
font-size: 12px;
font-weight: 400;
justify-content: center;
align-items: center;
border-radius: 32px;
background: var(--title-color); 
border: none;
}

@media screen and (max-width: 1441px) {
.container .form_containerrr .container .row .col-md-6 .divv{
    height: 320px !important;
}
.container .form_containerrr .container .row .col-md-6 .divvv{
    height: 320px !important;
}
}

@media screen and (max-width: 1206px) {
.container .form_containerrr .container .row .col-md-6 .divv{
    position: relative;
    width: 135%;
    left: -75px !important;

}
.container .form_containerrr .container .row .col-md-6 .divvv{
    position: relative;
    width: 135%;
    left: 25px !important;

}
}

@media screen and (max-width: 1202px) {
.work-together{
    margin-left: -18% !important;
}
}

@media screen and (max-width: 992px) {
.container .form_containerrr .container .row .col-md-6 .divv{
    position: relative;
    width: 300px !important;
    left: -170px !important;
}
.container .form_containerrr .container .row .col-md-6 .divvv{
    position: relative;
    width: 300px !important;
}

.work-together{  
    padding: 0;
    width: 650px;
    margin-left: -100px !important;
}

.work-together h6{
    margin-left: 120px !important;
}

.work-together button{
    position: relative; 
    left: -100px !important;
}
}

@media screen and (max-width: 938px) {
.our-products{
    position: relative;
    left: -100px !important;
}
}

@media screen and (max-width: 767.5px) {
.container .form_containerrr .container .row .col-md-6 .divvv{
    position: relative;
    top: 40px !important;
    left: -170px !important;
}

.work-together h6{
    margin-left: -30px !important;
}

.work-together button{
    position: relative; 
    left: -250px !important;
}
}


@media screen and (max-width: 767px) {
.container .form_containerrr .container .row .col-md-6 .divvv{
    position: relative;
    top: 40px !important;
    left: -170px !important;
}

.work-together h6{
    margin-left: -30px !important;
}

.work-together button{
    position: relative; 
    left: -250px !important;
}
}


@media screen and (max-width: 767px) {
.footer{
    margin-top: 400px !important;
}
}
@media screen and (max-width: 716px){
.footer{ 
  margin-top: 190px !important;
  position: relative;
}
}

@media screen and (max-width: 457px){
.footer{ 
  margin-top: 140px !important;
  position: relative;
}
}



@media screen and (max-width: 431px) {
.container .form_containerrr .container .row .col-md-6 .divvv{
    position: relative;
    top: 40px !important;
    left: -190px !important; 
}

.container .form_containerrr .container .row .col-md-6 .divv{
    left: -190px !important; 
}
.work-together h6{
    margin-left: -5% !important;
    font-size: 17px !important;
}

.work-together button{
    position: relative; 
    left: -275px !important;
}
}

@media screen and (max-width: 415.5px){
.footer{ 
  margin-top: 120px !important;
  position: relative;
}
}


@media screen and (max-width: 389px) {
.container .form_containerrr .container .row .col-md-6 .divvv{
    position: relative;
    top: 40px !important;
    left: -200px !important; 
}

.container .form_containerrr .container .row .col-md-6 .divv{
    left: -200px !important; 
}
.work-together h6{
    margin-left: -3% !important;
    font-size: 15px !important;
}

.work-together button{
    position: relative; 
    left: -275px !important;
}
}

@media screen and (max-width: 385.5px){
.footer{ 
  margin-top: 100px !important;
  position: relative;
}
}

@media screen and (max-width: 362.5px){
.footer{ 
  margin-top: -40px !important;
  position: relative;
}

.container .form_containerrr .container .row .col-md-6 .divvv{
    position: relative;
    top: 40px !important;
    left: -220px !important; 
}

.container .form_containerrr .container .row .col-md-6 .divv{
    left: -220px !important; 
}
.work-together h6{
    margin-left: -5% !important;
    font-size: 15px !important;
}

.work-together button{
    position: relative; 
    left: -285px !important;
}
}





</style>